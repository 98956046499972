


























import {
  useLocalFilter,
  useMapMasterTypeToOptionAlt,
  useSalesOrder,
} from "@/hooks";
import { Option } from "@/models/class/option.class";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectSalesType extends Vue {
  @Prop({ required: true, type: String, default: "" }) value!: string;
  @Prop({ required: false, type: Boolean, default: false }) disabled!: boolean;

  useLocalFilter = useLocalFilter;

  options: Option[] = [];
  loading = false;

  created(): void {
    this.fetchOptions();
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  fetchOptions(): void {
    const { findAllSoType } = useSalesOrder();
    this.loading = true;
    findAllSoType()
      .then(res => {
        const typesWithoutTrucking = res.filter(
          data => data.value !== "Trucking"
        );
        this.options = useMapMasterTypeToOptionAlt(typesWithoutTrucking);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
