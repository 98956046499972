var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: _vm.$t("lbl_reason"), visible: _vm.visible },
      on: {
        cancel: function($event) {
          return _vm.emits(false)
        }
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.validate }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_ok")) + " ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.model, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_reason"), prop: "reason" } },
            [
              _c("a-textarea", {
                attrs: { "allow-clear": "", rows: 4 },
                scopedSlots: _vm._u([
                  {
                    key: "extra",
                    fn: function() {
                      return [
                        _c("character-length", { attrs: { value: _vm.reason } })
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.model.reason,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "reason", $$v)
                  },
                  expression: "model.reason"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }