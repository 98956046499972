



























import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useInventory } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { InventoryLineResponseDto } from "@/models/interface/inventory";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

type Parameter = {
  productId: string;
  uomId: string;
  branchId: string;
};

@Component
export default class SelectLocationInventory extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: Object })
  parameter!: Parameter;

  options: Option<InventoryLineResponseDto>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);
  }

  @Watch("parameter.productId")
  onChangeProduct(): void {
    this.refetch();
  }

  @Watch("parameter.uomId")
  onChangeUom(): void {
    this.refetch();
  }

  @Watch("parameter.branchId")
  onChangeBranch(): void {
    this.refetch();
  }

  refetch(): void {
    const searchBy = this.buildQuery();
    this.options = [];
    if (searchBy) {
      const params = new RequestQueryParams(searchBy);
      this.fetchOptions(params);
    }
  }

  fetchOptions(params: RequestQueryParams): void {
    const { findAllInventoryLine } = useInventory();
    this.loading = true;
    findAllInventoryLine(params)
      .then(({ data }) => {
        this.options = data.map(item => ({
          label: item.warehouseLocationName,
          value: item.warehouseLocationId,
          key: item.id,
          meta: item,
        }));
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const builder = new SearchBuilder();
    const params = new RequestQueryParams();
    const query: string[] = [this.buildQuery()];

    if (val) {
      query.push(
        builder
          .push(["warehouseLocation.name", val], {
            like: "both",
          })
          .build()
      );
    }

    params.search = query.join(builder.AND);

    this.fetchOptions(params);
  }

  findOption(value: string): Option<InventoryLineResponseDto> | undefined {
    return this.options.find(e => value === e.value);
  }

  /**
   * @description build default query
   */
  buildQuery(): string {
    const builder = new SearchBuilder();

    if (
      !this.parameter ||
      !this.parameter.productId ||
      !this.parameter.branchId ||
      !this.parameter.uomId
    ) {
      return "";
    }

    return builder
      .push(["product.secureId", this.parameter.productId])
      .and()
      .push(["uom.secureId", this.parameter.uomId])
      .and()
      .push([
        "warehouseLocation.warehouse.branchWarehouse.secureId",
        this.parameter.branchId,
      ])
      .and()
      .push(["available", "0"], {
        ht: true,
      })
      .build();
  }
}
