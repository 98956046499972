








































































import { HelperTaxRegNo, InputTaxRegNo } from "@/components/InputTaxRegNo";
import { SelectTaxInvoiceCode } from "@/components/TaxInvoice";
import {
  DATE_FORMAT_YYYY_MM_DD,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { TaxBaseVariableParams } from "@/models/interface/tax-base-variable";
import { taxBaseVariableService } from "@/services/tax-base-variable.service";
import { FormValue, State } from "@/store/aRPrepayment.store";
import { checkTaxNumber } from "@/validator/globalvalidator";
import { FormModel } from "ant-design-vue";
import { Component, Ref, Vue } from "vue-property-decorator";
import { mapActions, mapMutations, mapState } from "vuex";

@Component({
  components: {
    SelectTaxInvoiceCode,
    InputTaxRegNo,
    HelperTaxRegNo,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.aRPrepaymentStore,
    }),
  },
  methods: {
    ...mapMutations({
      setForm: "aRPrepaymentStore/setForm",
    }),
    ...mapActions({
      calcPayment: "aRPrepaymentStore/calcPayment",
    }),
  },
})
export default class FormTax extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  @Ref("formModel")
  formModel!: FormModel;

  store!: State;
  setForm!: (payload: Partial<FormValue>) => void;
  calcPayment!: () => void;

  formRules = {
    customerTaxType: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    taxInvoiceDate: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    taxRegistrationNumber: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
      { min: 15, message: this.$t("lbl_validation_min_length_x", { x: 15 }) },
      {
        validator: checkTaxNumber,
      },
    ],
  };

  async fetchTaxBaseVariable(params: TaxBaseVariableParams): Promise<number> {
    try {
      const response = await taxBaseVariableService.getVariable(params);
      return response.value ?? 1;
    } catch {
      return 1;
    }
  }

  async onChangeLuxury(val: boolean): Promise<void> {
    const response = await this.fetchTaxBaseVariable({
      isLuxury: val,
      transactionDate:
        this.store.form.invoiceDate?.format(DATE_FORMAT_YYYY_MM_DD) ?? "",
    });
    this.setForm({ taxBaseVariable: response });
    this.calcPayment();
  }
}
