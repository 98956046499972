



























import { SearchBuilder } from "@/builder";
import { useLocalFilter, useProduct } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { PropType } from "vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

type Parameter = {
  branchId: string;
  productId: string;
};

@Component
export default class SelectSnSales extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: Object as PropType<Parameter> })
  parameter!: Parameter;

  useLocalFilter = useLocalFilter;

  options: Option[] = [];
  loading = false;

  @Watch("parameter.branchId")
  onChangeBranch(): void {
    this.refetch();
  }

  @Watch("parameter.productId")
  onChangeProduct(): void {
    this.refetch();
  }

  refetch(): void {
    if (!this.parameter.branchId || !this.parameter.productId) {
      return;
    }

    const params = new RequestQueryParams();
    const builder = new SearchBuilder();
    params.search = builder
      .push(["branchWarehouseId", this.parameter.branchId])
      .and()
      .push(["productId", this.parameter.productId])
      .build();
    this.options = [];
    this.fetchOptions(params);
  }

  fetchOptions(params: RequestQueryParams): void {
    const { findAllSnForSales } = useProduct();
    this.loading = true;
    findAllSnForSales(params)
      .then(res => {
        this.options = res.map((item, i) => ({
          label: item.serialNumber,
          value: item.serialNumber,
          key: i,
        }));
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  findOption(value: string): Option | undefined {
    return this.options.find(e => value === e.value);
  }
}
