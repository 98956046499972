




























































































































































import { SelectContactAddress } from "@/components/ContactData";
import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectCurrency from "@/components/custom/select/SelectCurrency.vue";
import SelectCustomer from "@/components/custom/select/SelectCustomer.vue";
import SelectMasterType from "@/components/custom/select/SelectMasterType.vue";
import { useContactData } from "@/hooks/contact";
import useCoa from "@/hooks/useCoa";
import useCurrency from "@/hooks/useCurrency";
import usePreferences from "@/hooks/usePreferences";
import { Option } from "@/models/class/option.class";
import { ONE } from "@/models/constant/global.constant";
import {
  DATE_FORMAT_YYYY_MM_DD,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { PREFERENCE_ACCOUNT_SETUP_KEY } from "@/models/enums/preference.enum";
import { TaxBaseVariableParams } from "@/models/interface/tax-base-variable";
import LocalStorageService from "@/services/LocalStorage.service";
import { taxBaseVariableService } from "@/services/tax-base-variable.service";
import {
  State as ArPrepaymentStore,
  FormValue,
} from "@/store/aRPrepayment.store";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { AccountingAccountResponseDto } from "@interface/accounting-account";
import {
  AddressDataDto,
  DetailContactDataDto,
  ListContactDataDto,
} from "@interface/contact-data";
import {
  AccountingCurrencyResponseDTO,
  ResponsePreference,
} from "@interface/settings.interface";
import { FormModel } from "ant-design-vue";
import { Moment } from "moment";
import { Component, Ref, Vue } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import SelectReceivableAccount from "./SelectReceivableAccount.vue";

const EXCLUDE_INVOICE_TYPE = "INVOICE AR";

@Component({
  components: {
    SelectBranch,
    SelectMasterType,
    SelectCustomer,
    SelectContactAddress,
    SelectCurrency,
    SelectReceivableAccount,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.aRPrepaymentStore as ArPrepaymentStore,
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
    ...mapGetters({
      isIdr: "aRPrepaymentStore/isIdr",
    }),
  },
  methods: {
    ...mapMutations({
      setForm: "aRPrepaymentStore/setForm",
      setRegisteredCustTax: "aRPrepaymentStore/setRegisteredCustTax",
    }),
    ...mapActions({
      calcPayment: "aRPrepaymentStore/calcPayment",
    }),
  },
})
export default class FormHeader extends Vue {
  reverseFormatNumber = reverseFormatNumber;
  formatterNumber = formatterNumber;

  EXCLUDE_INVOICE_TYPE = EXCLUDE_INVOICE_TYPE;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  @Ref("formModel")
  formModel!: FormModel;

  store!: ArPrepaymentStore;
  isIdr!: boolean;
  storeBaseDecimalPlace!: number;
  setForm!: (payload: Partial<FormValue>) => void;
  setRegisteredCustTax!: (custTax: boolean) => void;
  calcPayment!: () => void;

  formRules = {
    branchWarehouseId: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    invoiceType: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    customerId: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    customerShipToAddress: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    customerBillToAddress: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    termOfPayment: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    invoiceDate: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    accountingDate: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    currency: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    receivableAccountName: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    lineAccountName: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  custAddresses: Array<AddressDataDto> = [];

  mounted(): void {
    this.initLineAccount();
    if (this.isIdr) {
      this.initArAccount();
    }
  }

  /**
   * @description
   * set default value for line account field
   */
  initLineAccount(): void {
    const { findById, toOptions } = useCoa();
    const { findByKey } = usePreferences();
    const pref: ResponsePreference | undefined = findByKey(
      PREFERENCE_ACCOUNT_SETUP_KEY.ACCOUNT_SETUP_PREPAYMENT_AR
    );
    this.setForm({
      lineAccountId: "",
      lineAccountName: "",
    });
    if (!pref || !pref.value) return;
    findById(pref.value).then(res => {
      const [option] = toOptions([res]);
      this.setForm({
        lineAccountId: option.value,
        lineAccountName: option.label,
      });
    });
  }

  /**
   * @description
   * set default value for receivable account field
   */
  initArAccount(): void {
    const { findById, toOptions } = useCoa();
    const { findByKey } = usePreferences();
    const pref: ResponsePreference | undefined = findByKey(
      PREFERENCE_ACCOUNT_SETUP_KEY.ACCOUNT_RECEIVABLE
    );
    this.setForm({
      receivableAccountId: "",
      receivableAccountName: "",
    });
    if (!pref || !pref.value) return;
    findById(pref.value).then(res => {
      const [option] = toOptions([res]);
      this.setForm({
        receivableAccountId: option.value,
        receivableAccountName: option.label,
      });
    });
  }

  getDetailCustomer(
    custId: string,
    cb: (dt: DetailContactDataDto) => void
  ): void {
    const { findOne } = useContactData();
    findOne(custId).then(cb);
  }

  getTaxRegisNo(taxNumber: string, billAddress?: AddressDataDto): string {
    const emptyTaxNumber: boolean = taxNumber === " " || taxNumber === "-";
    const emptyIdCardNumber: boolean =
      !!billAddress &&
      (billAddress.idCardNumber === " " || billAddress.idCardNumber === "-");

    let taxRegisNumber = taxNumber;
    if (billAddress && emptyTaxNumber && emptyIdCardNumber) {
      taxRegisNumber = billAddress.idCardNumber;
    }

    return taxRegisNumber;
  }

  getTaxRegName(taxName: string): string {
    const isEmpty: boolean = taxName === " " || taxName === "-";
    const taxRegisName: string = isEmpty ? "" : taxName;
    return taxRegisName;
  }

  setCustomerTax({
    taxRegisNumber,
    taxRegisName,
    customerData: { taxInvoiceCode },
    addressDataList,
    top,
  }: DetailContactDataDto): void {
    const primaryBillTo = addressDataList.find(
      e => e.billTo && e.primaryBillTo
    );
    const taxRegNo: string = this.getTaxRegisNo(taxRegisNumber, primaryBillTo);
    const taxRegName: string = this.getTaxRegName(taxRegisName);
    this.setForm({
      termOfPayment: top?.toString(),
      taxRegistrationNumber: taxRegNo,
      taxRegistrationName: taxRegName,
      customerTaxType: taxInvoiceCode,
    });
  }

  updateCustAddresses(addresses: Array<AddressDataDto>): void {
    this.custAddresses = addresses;
  }

  getDefaultAddress(addresses: Array<AddressDataDto>): {
    bill: string;
    ship: string;
  } {
    const { getDefaultBillToAddress, getDefaultShipToAddress } =
      useContactData();
    return {
      bill: getDefaultBillToAddress(addresses),
      ship: getDefaultShipToAddress(addresses),
    };
  }

  onChangeCustomer(e?: Option<ListContactDataDto>): void {
    const custId: string = e?.meta?.id || "";
    this.updateCustAddresses(e?.meta?.addressDataList || []);
    const { bill, ship } = this.getDefaultAddress(this.custAddresses);

    // reset fields
    this.setForm({
      termOfPayment: undefined,
      taxRegistrationNumber: "",
      taxRegistrationName: "",
      customerTaxType: "",
      taxInvoiceNumber: "",
      customerShipToAddress: ship,
      customerBillToAddress: bill,
    });
    this.setRegisteredCustTax(false);

    if (!custId) return;
    this.getDetailCustomer(custId, res => {
      this.setRegisteredCustTax(!!res.taxRegisName);
      this.setCustomerTax(res);
    });
  }

  getCurrencyRates(cb: (rate: number) => void): void {
    const base: string = LocalStorageService.loadCurrency() || "";
    const { currency } = this.store.form;
    const { findConversion } = useCurrency();
    findConversion(base, currency).then(({ data }) => {
      const [curr] = data;
      cb(curr?.rate || ONE);
    });
  }

  onChangeCurrency(e?: Option<AccountingCurrencyResponseDTO>): void {
    this.setForm({
      currency: e?.meta?.currencyCode || "",

      // reset fields
      receivableAccountId: "",
      receivableAccountName: "",
      currencyRate: ONE,
    });

    if (this.isIdr) {
      this.initArAccount();
    } else {
      this.getCurrencyRates(currencyRate => {
        this.setForm({ currencyRate });
      });
    }
  }

  async onChangeInvoiceDate(e?: Moment): Promise<void> {
    this.setForm({
      accountingDate: e,
      taxInvoiceDate: e,
    });

    const response = await this.fetchTaxBaseVariable({
      isLuxury: this.store.form.isLuxury,
      transactionDate: e?.format(DATE_FORMAT_YYYY_MM_DD) ?? "",
    });

    this.setForm({ taxBaseVariable: response });
    this.calcPayment();
  }

  onChangeArAcc(e?: Option<AccountingAccountResponseDto>): void {
    this.setForm({
      receivableAccountId: e?.meta?.id || "",
      receivableAccountName: e?.label || "",
    });
  }

  async fetchTaxBaseVariable(params: TaxBaseVariableParams) {
    try {
      const response = await taxBaseVariableService.getVariable(params);
      return response.value ?? 1;
    } catch {
      return 1;
    }
  }
}
