



















import { useSalesOrder } from "@/hooks";
import { SalesOrderCloseRequestDto } from "@/models/interface/sales-order";
import { FormModel } from "ant-design-vue";
import { Component, Vue, Prop, Ref } from "vue-property-decorator";

@Component
export default class ModalCloseSo extends Vue {
  @Prop({ required: true, type: Boolean, default: false })
  visible!: boolean;

  @Prop({ required: true, type: String, default: "" })
  soId!: string;

  @Ref("form")
  form!: FormModel;

  loading = false;
  model = {
    reason: "",
  };
  rules = {
    reason: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  validate(): void {
    this.form.validate((valid: boolean) => {
      if (!valid) return;

      this.handleCloseSo();
    });
  }

  handleCloseSo(): void {
    const { closeSo, initCloseSoDto } = useSalesOrder();
    const req: SalesOrderCloseRequestDto = initCloseSoDto();
    req.reasonToClose = this.model.reason;

    this.loading = true;
    closeSo(this.soId, req)
      .then(() => {
        this.emits(true);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  emits(redirect: boolean): void {
    this.$emit("cancel", { redirect });
  }
}
