



























import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useProduct } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ProductTypeEnum } from "@/models/enums/ProductType.enum";
import { ProductResponseDto } from "@/models/interface/master-product";
import { Vue, Component, Prop } from "vue-property-decorator";

/**
 * @description select component product service for sales order
 */
@Component
export default class SelectProductService extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: String, default: "code" })
  optionLabel!: "code" | "name";

  options: Option<ProductResponseDto>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);
    const params = new RequestQueryParams(this.buildQuery());
    this.fetchOptions(params);
  }

  fetchOptions(params: RequestQueryParams): void {
    const { findAll } = useProduct();

    this.loading = true;
    findAll(params)
      .then(({ data }) => {
        this.options = data.map(item => ({
          label: item[this.optionLabel],
          value: item.id,
          key: item.id,
          meta: item,
        }));
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { searchBy } = useProduct();
    const builder = new SearchBuilder();
    const params = new RequestQueryParams();
    const defaultQuery: string[] = [this.buildQuery()];
    const filtered: string = searchBy({ [this.optionLabel]: val });

    if (filtered) {
      defaultQuery.push(filtered);
    }

    params.search = defaultQuery.join(builder.AND);

    this.fetchOptions(params);
  }

  findOption(value: string): Option<ProductResponseDto> | undefined {
    return this.options.find(e => value === e.value);
  }

  /**
   * build default query
   */
  buildQuery(): string {
    const builder = new SearchBuilder();

    return builder.push(["type", ProductTypeEnum.SERVICE]).build();
  }
}
