

































import { Vue, Component } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters({
      getTotalDpp: "salesOrderStore/getTotalDpp",
      getTotalTax: "salesOrderStore/getTotalTax",
      getTotalDiscount: "salesOrderStore/getTotalDiscount",
      getGrandTotal: "salesOrderStore/getGrandTotal",
    }),
  },
})
export default class DisplayTotal extends Vue {
  getTotalDpp!: number;
  getTotalTax!: number;
  getTotalDiscount!: number;
  getGrandTotal!: number;
}
