var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    [
      _c("legend", [_vm._v(_vm._s(_vm.$t("lbl_tax")))]),
      _c(
        "a-form-model",
        {
          ref: "formModel",
          attrs: {
            model: _vm.store.form,
            rules: _vm.formRules,
            "wrapper-col": { span: 12 },
            "label-col": { span: 8 },
            "label-align": "left"
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_customer_tax_type"),
                        prop: "customerTaxType"
                      }
                    },
                    [
                      _c("SelectTaxInvoiceCode", {
                        model: {
                          value: _vm.store.form.customerTaxType,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "customerTaxType", $$v)
                          },
                          expression: "store.form.customerTaxType"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_tax_registration_number"),
                        prop: "taxRegistrationNumber"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "extra",
                          fn: function() {
                            return [_c("HelperTaxRegNo")]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("InputTaxRegNo", {
                        model: {
                          value: _vm.store.form.taxRegistrationNumber,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.store.form,
                              "taxRegistrationNumber",
                              $$v
                            )
                          },
                          expression: "store.form.taxRegistrationNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_tax_registration_name"),
                        prop: "taxRegistrationName"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: _vm.store.registeredCustTax },
                        model: {
                          value: _vm.store.form.taxRegistrationName,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "taxRegistrationName", $$v)
                          },
                          expression: "store.form.taxRegistrationName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_tax_invoice_date"),
                        prop: "taxInvoiceDate",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-date-picker", {
                        attrs: { format: _vm.DEFAULT_DATE_FORMAT },
                        model: {
                          value: _vm.store.form.taxInvoiceDate,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "taxInvoiceDate", $$v)
                          },
                          expression: "store.form.taxInvoiceDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_tax_invoice_number"),
                        prop: "taxInvoiceNumber",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { "allow-clear": "" },
                        model: {
                          value: _vm.store.form.taxInvoiceNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "taxInvoiceNumber", $$v)
                          },
                          expression: "store.form.taxInvoiceNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_luxury_goods"),
                        "label-align": "right",
                        prop: "isLuxury"
                      }
                    },
                    [
                      _c("a-switch", {
                        on: { change: _vm.onChangeLuxury },
                        model: {
                          value: _vm.store.form.isLuxury,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "isLuxury", $$v)
                          },
                          expression: "store.form.isLuxury"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }