

































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DisplayTotal extends Vue {
  @Prop({ required: false, default: "0" })
  subTotal!: string;

  @Prop({ required: false, default: "0" })
  baseAmount!: string;

  @Prop({ required: false, default: "0" })
  totalTax!: string;

  @Prop({ required: false, default: "0" })
  total!: string;
}
