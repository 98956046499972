



























import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useAsset } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { AssetStateEnum } from "@/models/enums/master-asset.enum";
import { AssetResponseDto } from "@/models/interface/master-asset";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

/**
 * @description select component available asset for sales order
 */
@Component
export default class SelectAvailableAsset extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: String, default: "" })
  branchId!: string;

  options: Option<AssetResponseDto>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);
  }

  @Watch("branchId", { immediate: true })
  onChangeBranchId(): void {
    const params = new RequestQueryParams(this.buildQuery());
    this.fetchOptions(params);
  }

  fetchOptions(params: RequestQueryParams): void {
    const { findAllAvailableAssetSo, toOptionsRemastered } = useAsset();

    this.loading = true;
    findAllAvailableAssetSo(params)
      .then(({ data }) => {
        this.options = toOptionsRemastered(data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const builder = new SearchBuilder();
    const { filterBy } = useAsset();
    const params = new RequestQueryParams();
    const query: string[] = [this.buildQuery()];
    const filtered: string = filterBy({ unitCode: val });

    if (filtered) {
      query.push(filtered);
    }

    params.search = query.join(builder.AND);

    this.fetchOptions(params);
  }

  findOption(value: string): Option<AssetResponseDto> | undefined {
    return this.options.find(e => value === e.value);
  }

  /**
   * @description build default query
   */
  buildQuery(): string {
    const builder = new SearchBuilder();
    const q: string[] = [
      builder
        .push(["status", AssetStateEnum.NEED_TO_REPAIR])
        .or()
        .push(["status", AssetStateEnum.READY])
        .or()
        .push(["status", AssetStateEnum.RENTED])
        .build(),
    ];

    if (this.branchId) {
      q.push(
        builder
          .push([
            "assetLocation.warehouse.branchWarehouse.secureId",
            this.branchId,
          ])
          .build()
      );
    }

    return q.join(builder.AND);
  }
}
